@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400);
:root {
  --color-animation: #ff4136;
}

.App {
  text-align: center;
}

body {
  font-family: "Source Sans Pro";
  margin: 20px;
}

.help {
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #ff4136;
    background-color: var(--color-animation);
  }
}

@keyframes pulse {
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #ff4136;
    background-color: var(--color-animation);
  }
}

body {
  text-align: center;
}

li {
  list-style: none;
}

/**.Hal-name {
  font-weight: bold;
  font-size: 21px;
  padding-bottom: 5px;
  color: #042a2b;
}*/

.Hal-name button {
  margin: 5% 2% 0%;
}

.Hal .underline {
  text-decoration: none;
  border: 4px solid transparent;
  display: inline-block;
  vertical-align: top;
  line-height: 36px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 0.2em;
  text-align: center;
  font-size: 1.4rem;
  margin: 10px;
  position: relative;
}

.underline::after {
  width: 0%;
  height: 4px;
  display: block;
  background-color: black;
  content: "";
  position: absolute;
  top: 34px;
  left: 50%;
  transition: left 0.4s cubic-bezier(0.125, 0.61, 0.355, 1), width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.underline::after {
  width: 100%;
  top: 34px;
  left: 0;
}
.Navbar-icons {
  cursor: pointer;
}

.Navbar-icons i {
  padding: 5px;
  font-size: 20px;
  margin: 10px;
}

.Navbar-icons span {
  padding: 5px;
}

.body {
  font-family: "Source Sans Pro";
}

.btn-info {
  margin-top: 5%;
}

.Hal {
  border: 3px solid black;
  margin: 2% 10%;
}

.Hal i {
  color: #5eb1bf;
}

.Hal i:hover {
  color: #042a2b;
}

.Hal h2 {
  color: #ef7b45;
  font-weight: bold;
  font-size: 1.5rem;
}

.HalDetails-AppNaam {
  display: inline-block;
  padding: 2%;
  font-weight: bold;
  font-size: 1.1rem;
}
p {
    margin: 0;
    padding: 0;
}
.form-check {
  margin-right: 5%;
}

.btn-primary {
  margin: 0 2% 1%;
}

.AppDetails-formTitle {
  padding: 1%;
}

.AppDetails-actieUitgevoerd {
  margin: 2%;
}

.AppDetails-volgende {
  display: inline-block;
}

.ColorSettings-Title {
  margin: 0% 2%;
}

.ColorSettings-Section {
  display: flex;
  align-self: flex-start;
}

.ColorSettings-Section {
  font-size: small;
}

.jumbotron {
  background-color: #d84727;
  color: #d7eef4;
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: 0.15em;
  padding: 0.15em;
  border-radius: 5px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.header-colorsettings {
  flex: 1 1;
}

.container::after {
  content: "";
  flex: 1 1;
}

.container i {
  font-size: 2em;
  padding: 0.15em;
  margin-left: 0.25em;
  color: #d7eef4;
}


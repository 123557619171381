.form-check {
  margin-right: 5%;
}

.btn-primary {
  margin: 0 2% 1%;
}

.AppDetails-formTitle {
  padding: 1%;
}

.AppDetails-actieUitgevoerd {
  margin: 2%;
}

.AppDetails-volgende {
  display: inline-block;
}

.body {
  font-family: "Source Sans Pro";
}

.btn-info {
  margin-top: 5%;
}

.Hal {
  border: 3px solid black;
  margin: 2% 10%;
}

.Hal i {
  color: #5eb1bf;
}

.Hal i:hover {
  color: #042a2b;
}

.Hal h2 {
  color: #ef7b45;
  font-weight: bold;
  font-size: 1.5rem;
}

.HalDetails-AppNaam {
  display: inline-block;
  padding: 2%;
  font-weight: bold;
  font-size: 1.1rem;
}
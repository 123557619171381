.Navbar-icons {
  cursor: pointer;
}

.Navbar-icons i {
  padding: 5px;
  font-size: 20px;
  margin: 10px;
}

.Navbar-icons span {
  padding: 5px;
}
